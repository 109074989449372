export const skillsDefs = [
  {
    "name": "tolerance",
    "title": "Tolérance aux risques",
    "definition": "Capacité à traiter des informations ambiguë : contradictoires, changeantes et incertaines",
    "title_en": "Risk tolerance",
    "definition_en": "Ability to process ambiguous information: contradictory, changing and uncertain"
  },
  {
    "name": "divergent",
    "title": "Raisonnement divergent",
    "definition": "Capacité à générer des idées ou des solutions nouvelles",
    "title_en": "Divergent reasoning",
    "definition_en": "Ability to generate new ideas or solutions"
  },
  {
    "name": "controle",
    "title": "Contrôle émotionnel",
    "definition": "Capacité à déclencher, inhiber, maintenir et moduler ses émotions",
    "title_en": "Emotional control",
    "definition_en": "Ability to trigger, inhibit, maintain and modulate emotions"
  },
  {
    "name": "flexible",
    "title": "Flexibilité mentale",
    "definition": "Capacité à effectuer plusieurs tâches en même temps ou à passer d’une tâche à une autre mais aussi capacité à envisager un sujet sous différents angles",
    "title_en": "Mental flexibility",
    "definition_en": "Ability to multitask or switch between tasks, as well as the ability to view a subject from different angles"
  },
  {
    "name": "persevere",
    "title": "Persévérance",
    "definition": "Capacité à continuer à effectuer des actions en vue d’un objectif malgré les obstacles",
    "title_en": "Perseverance",
    "definition_en": "Ability to continue to perform actions toward a goal despite obstacles"
  },
  {
    "name": "planifie",
    "title": "Planification",
    "definition": "Capacité à planifier des tâches à effectuer dans le temps et à respecter cette planification et les délais et à anticiper.",
    "title_en": "Planning",
    "definition_en": "Ability to plan tasks to be carried out over time and to respect this planning and deadlines and to anticipate."
  },
  {
    "name": "influence",
    "title": "Influence",
    "definition": "Capacité à impacter les croyances ou les comportements des autres",
    "title_en": "Influence",
    "definition_en": "Ability to impact the beliefs or behaviors of others"
  },
  {
    "name": "empathie",
    "title": "Empathie",
    "definition": "Capacité à déduire l’état mental, l’humeur, les besoins et les sentiments des autres",
    "title_en": "Empathy",
    "definition_en": "Ability to infer the mental state, mood, needs, and feelings of others"
  },
  {
    "name": "logique",
    "title": "Raisonnement logique",
    "definition": "Capacité d’inférence, qui permet d’établir des liens entre l’apparition d’évènements et d’en tirer des patterns statistiques.",
    "title_en": "Logical reasoning",
    "definition_en": "Inference capacity, which allows establishing links between the occurrence of events and deriving statistical patterns from them."
  },
  {
    "name": "assertive",
    "title": "Assertivité",
    "definition": "Capacité à défendre ses opinions et ses droits fermement mais respectueusement",
    "title_en": "Assertiveness",
    "definition_en": "Ability to defend one's opinions and rights firmly but respectfully"
  },
  {
    "name": "autonome",
    "title": "Autonomie",
    "definition": "Capacité à accomplir des tâches et se fixer des objectifs avec peu ou pas de supervision",
    "title_en": "Autonomy",
    "definition_en": "Ability to complete tasks and set goals with little or no supervision"
  },
  {
    "name": "curieux",
    "title": "Curiosité",
    "definition": "Capacité à porter de l’intérêt à une grande variété de domaines",
    "title_en": "Curiosity",
    "definition_en": "Ability to demonstrate interest in a wide variety of fields"
  },
  {
    "name": "ouvert",
    "title": "Ouverture",
    "definition": "Capacité à être réceptif aux idées, expériences et approches nouvelles et à y adhérer",
    "title_en": "Opening",
    "definition_en": "Ability to be receptive to and embrace new ideas, experiences and approaches"
  },
  {
    "name": "synthese",
    "title": "Raisonnement synthètique",
    "definition": "Capacité à sélectionner et restituer les informations les plus pertinentes provenant plusieurs sources",
    "title_en": "Synthetic reasoning",
    "definition_en": "Ability to select and restore the most relevant information from multiple sources"
  },
  {
    "name": "feedback",
    "title": "Faire du feedback positif",
    "definition": "Capacité à apprécier et à reconnaître le travail et les compétences des personnes avec qui l’on travaille",
    "title_en": "Give positive feedback",
    "definition_en": "Ability to appreciate and recognize the work and skills of the people you work with"
  },
  {
    "name": "intuitif",
    "title": "Raisonnement intuitif",
    "definition": "Capacité à analyser une situation rapidement grâce à ses expériences passées",
    "title_en": "Intuitive reasoning",
    "definition_en": "Ability to analyze a situation quickly based on past experiences"
  },
  {
    "name": "parole",
    "title": "Prise de parole en public",
    "definition": "Capacité à partager des informations de manière claire, appropriée et efficace oralement",
    "title_en": "public speaking",
    "definition_en": "Ability to share information clearly, appropriately and effectively orally"
  },
  {
    "name": "attentif",
    "title": "Etre attentif à l’autre",
    "definition": "Capacité à chercher activement à comprendre ce que dit un interlocuteur et à se montrer disponible lorsqu’il s’exprime",
    "title_en": "Being attentive to others",
    "definition_en": "Ability to actively seek to understand what an interlocutor is saying and to be available when they are speaking"
  },
  {
    "name": "monitoring",
    "title": "Monitoring",
    "definition": "Capacité à suivre, modifier et améliorer ses stratégies",
    "title_en": "Monitoring",
    "definition_en": "Ability to monitor, modify and improve strategies"
  },
  {
    "name": "efficace",
    "title": "Efficacité",
    "definition": "Capacité à produire un maximum de résultats en minimisant les dépenses en temps et en énergie",
    "title_en": "Efficiency",
    "definition_en": "Ability to produce maximum results while minimizing expenditure of time and energy"
  },
  {
    "name": "positif",
    "title": "Positivité",
    "definition": "Capacité à analyser et capitaliser sur les éléments positifs d’une situation.",
    "title_en": "Positivity",
    "definition_en": "Ability to analyze and capitalize on the positive elements of a situation."
  },
  {
    "name": "emotion",
    "title": "Identification émotionnelle",
    "definition": "Capacité à identifier ses émotions et celles des autres",
    "title_en": "Emotional identification",
    "definition_en": "Ability to identify one's emotions and those of others"
  },
  {
    "name": "reactif",
    "title": "Réactivité",
    "definition": "Capacité à réagir rapidement face à des situations ou sollicitations inattendues et ajuster ses priorités en fonction",
    "title_en": "Reactivity",
    "definition_en": "Ability to react quickly to unexpected situations or demands and adjust priorities accordingly"
  },
  {
    "name": "initiative",
    "title": "Prise d’initiative",
    "definition": "Capacité à effectuer des tâches de manière spontanée, sans qu’on nous le demande",
    "title_en": "Taking initiatives",
    "definition_en": "Ability to perform tasks spontaneously, without being asked"
  },
  {
    "name": "multitache",
    "title": "Multitache",
    "definition": "Capacité à gérer plusieurs tâches, tout en les priorisant",
    "title_en": "Multitasking",
    "definition_en": "Ability to manage multiple tasks, while prioritizing them"
  }
]
